import React from "react";
import "./Explainer.css";
// import lang from "./lang";

const Explainer = () => {
  return (
    <div className="explainer">
      <div className="content">
        <div className="expalainer-container">
          <div className="explainer-title">
            Wanneer ben je financieel in balans?
          </div>
          <div className="explainer-paragraphs-container">
            <div className="explainer-paragraph-small">
              Je kunt al je vaste lasten betalen, zoals de huur of hypotheek van
              je huis en je zorgverzekering.
            </div>
            <div className="explainer-paragraph-small">
              Je houdt geld over om dingen te doen waar je blij van wordt, zoals
              op vakantie gaan, sporten of uit eten gaan.
            </div>
            <div className="explainer-paragraph-small">
              Je hebt geld achter de hand voor (grote) onverwachte uitgaven,
              zoals een nieuwe wasmachine.
            </div>
            <div className="explainer-paragraph-small">
              Je kunt wat geld sparen voor de toekomst, bijvoorbeeld voor een
              studie of je pensioen.
            </div>
          </div>
          <div className="explainer-title">
            Waarom is de Balans Check belangrijk?
          </div>
          <div className="explainer-paragraph-big">
            Mensen die financieel in balans zijn hebben minder vaak
            geldproblemen en zijn gelukkiger. Met de Balans Check breng jij jouw
            financiële situatie in kaart. Een goed inzicht in je financiën is de
            eerste stap om je financiële gezondheid te verbeteren. De Balans
            Check laat zien hoeveel controle je hebt over de vijf pijlers van
            financiële gezondheid: Inkomen, Uitgaven, Sparen, Lenen en
            Plannen.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Explainer;
