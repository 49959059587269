import React from "react";
import StatusIcon from "../StatusIcon";
import TipCardExpand from "../TipCardExpand";
import TipTriangle from "./TipTriangle";

const TipCard = ({
  id,
  title,
  text,
  color,
  active,
  handleClick,
  placeholder,
}: {
  id: string;
  title: string;
  text: string;
  color: string;
  active: boolean;
  handleClick: any;
  placeholder?: boolean;
}) => {
  return (
    <div
      className={`tip-card ${active ? "tip-card__active" : ""} ${
        placeholder ? "tip-card__placeholder" : ""
      }`}
    >
      <StatusIcon color={color} />

      <div className="tip-card-title">{title}</div>

      <div className="tip-card-text">{text}</div>

      <TipCardExpand active={active} handleClick={() => handleClick(id)} />

      {active && <TipTriangle />}
    </div>
  );
};

export default TipCard;
