const Privacy = () => {
  return (
    <div className="content content-inst">
      <h1 style={{ fontSize: 32 }}>Privacyverklaring</h1>

      <p>
        Wij vragen niet opzettelijk naar, en verzamelen of verwerken niet jouw
        persoonsgegevens. Persoonsgegevens zijn gegevens waarmee een natuurlijke
        persoon kan worden geïdentificeerd of die aan een natuurlijke persoon
        kunnen worden gekoppeld.
      </p>
      <p>De Balans Check gebruikt uitsluitend niet-persoonlijke gegevens.</p>
      <p>
        Deze website maakt wel gebruik van cookies. Cookies zijn kleine
        tekstbestanden die op je computer, tablet of smartphone worden
        opgeslagen als je de website van de Balans Check bezoekt. Lees hier meer
        over{" "}
        <a
          href="/cookiebeleid"
          style={{ color: "blue", textDecoration: "underline" }}
        >
          ons cookie beleid.
        </a>
      </p>
    </div>
  );
};

export default Privacy;
