import { useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Button from "../../components/Button";
import IntroductionCard from "../../components/IntroductionCard";
import data from "../../data/data.json";
import "./../../App.css";
import "../../components/ProgressBar/ProgressBar.css";
import "./Test.css";
import "../../components/IntroductionCard/IntroductionCard.css";
import ProgressBar from "../../components/ProgressBar";

const letters = ["A", "B", "C", "D", "E", "F", "G", "H"];

const Test = ({
  answers,
  setAnswers,
  isComplete,
}: {
  answers: any;
  setAnswers: any;
  isComplete: any;
}) => {
  const history = useHistory();
  const questionRefs: any = useRef({});
  const [displayIncompleteError, setDisplayIncompleteError] = useState(false);

  const scrollToQuestion = (id: string) => {
    window.scroll({
      top: questionRefs.current[id].offsetTop - 80,
      behavior: "smooth",
    });
  };

  const getFirstIncompleteQuestion = () => {
    for (let question of data.questions) {
      if (!answers[question.id]) {
        return question;
      }
    }
    return { id: "" };
  };

  return (
    <div className="">
      <div className="questions-introduction">
        <div className="question-introduction-cards">
          <IntroductionCard />
        </div>
      </div>

      <div className="content" style={{ marginTop: "6rem" }}>
        <div className="introduction-description">
          <p>
            Hoe financieel gezond ben jij? Hoe ga je om met je inkomen,
            uitgaven, spaargeld, leningen en financiële planning? Met deze
            vragenlijst brengen we dat in kaart. De vragen gaan over jouw gehele
            huishouden, tenzij anders vermeld. Dat wil zeggen: ze gaan over jou
            en de leden van jouw huishouden met wie je de financiën deelt.
            Bijvoorbeeld jouw partner en inwonende kinderen. Als je alleen
            woont, kun je de vragen namens jezelf invullen.
          </p>
          <p>
            Jouw antwoorden worden enkel gebruikt voor de Balans Check en worden
            na het sluiten van de sessie niet opgeslagen. Lees meer over hoe we
            omgaan met jouw data in onze{" "}
            <Link to={"/privacyverklaring"}>Privacyverklaring</Link>.
          </p>
        </div>

        <ProgressBar answers={answers} />

        <ul className="App-question-list">
          {data.questions.map((question, questionIndex) => {
            return (
              <li
                className="question"
                ref={(ref) => (questionRefs.current[question.id] = ref)}
                key={question.id}
              >
                <div className="question-number">
                  Vraag ({question.number}/{data.questions.length})
                  {displayIncompleteError && !answers[question.id] && (
                    <span>*(verplichte vraag)</span>
                  )}
                </div>

                <div className="question-container">
                  <div className="question-text">{question.question}</div>
                </div>

                <div className="answer-container">
                  {question.answers.map((answer: any, index: number) => {
                    const option = letters[index];
                    const text = `${option}. ${answer.text}`;

                    return (
                      <div
                        key={answer.id}
                        onClick={() => {
                          setAnswers({
                            ...answers,
                            [question.id]: answer.id,
                          });

                          if (questionIndex < data.questions.length - 1) {
                            scrollToQuestion(
                              data.questions[questionIndex + 1].id
                            );
                          }
                        }}
                        className={`answer ${
                          answers[question.id] &&
                          answers[question.id] === answer.id
                            ? "answer__selected"
                            : ""
                        }`}
                      >
                        <span data-text={text}>{text}</span>
                      </div>
                    );
                  })}
                </div>
              </li>
            );
          })}
        </ul>

        <div
          className={`result-button ${
            isComplete() ? "" : "result-button-inactive"
          }`}
        >
          <Button
            text="Naar resultaat"
            onClick={() => {
              if (isComplete()) {
                setDisplayIncompleteError(false);
                window.scroll({ top: 0 });
                history.push("/resultaten");
              } else {
                setDisplayIncompleteError(true);
                scrollToQuestion(getFirstIncompleteQuestion().id);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Test;
