import Button from "../../components/Button";

const ResultsPopup = ({
  handleTest,
  handleResults,
}: {
  handleTest: any;
  handleResults: any;
}) => {
  return (
    <div className="popup">
      <div className="popup-container">
        <div className="popup-text">
          <h1>Weet je zeker dat je de test nog een keer wilt doen?</h1>
          <p>
            Jouw resultaten zullen worden verwijderd als de je test opnieuw
            uitvoert
          </p>
        </div>

        <div className="popup-buttons">
          <Button
            text="Doe de test opnieuw"
            color="green"
            onClick={handleTest}
          />

          <Button
            text="Terug naar resultaten"
            color="white"
            onClick={handleResults}
          />
        </div>
      </div>
    </div>
  );
};

export default ResultsPopup;
