import React from "react";

const NotFound = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="337"
      height="337"
      fill="none"
      viewBox="0 0 337 337"
    >
      <g clipPath="url(#clip0)">
        <path
          fill="#E4E4E4"
          d="M332.334 81.353H154.841a4.668 4.668 0 01-4.665-4.665V4.665A4.668 4.668 0 01154.841 0h177.493a4.669 4.669 0 014.665 4.666v72.022a4.668 4.668 0 01-4.665 4.665zM315.434 115.902H171.743a5.598 5.598 0 110-11.197h143.691c7.22-.101 7.292 11.299 0 11.197z"
        ></path>
        <path
          fill="#3F3D56"
          d="M270.168 211.51H217.01a5.597 5.597 0 110-11.197h53.158c7.22-.101 7.292 11.299 0 11.197z"
        ></path>
        <path
          fill="#E4E4E4"
          d="M315.434 141.563H171.743a5.599 5.599 0 010-11.198h143.691c7.22-.1 7.292 11.299 0 11.198zM315.435 167.223H171.743a5.595 5.595 0 01-5.604-5.599 5.605 5.605 0 015.604-5.598h143.692c7.22-.101 7.292 11.299 0 11.197z"
        ></path>
        <path
          fill="#6C63FF"
          d="M134.338 232.266a4.654 4.654 0 00-4.65 4.651v95.357a4.654 4.654 0 004.65 4.652h11.441a4.659 4.659 0 004.651-4.652v-95.357a4.659 4.659 0 00-4.651-4.651h-11.441zM111.709 232.266a4.654 4.654 0 00-4.65 4.651v95.357a4.654 4.654 0 004.65 4.652h11.441a4.657 4.657 0 004.651-4.652v-95.357a4.654 4.654 0 00-4.651-4.651h-11.441zM128.403 132.363c21.833 0 39.532-17.7 39.532-39.534s-17.699-39.534-39.532-39.534c-21.833 0-39.532 17.7-39.532 39.534s17.699 39.534 39.532 39.534z"
        ></path>
        <path
          fill="#2F2E41"
          d="M156.768 109.424c2.562-.071 5.752-.16 8.208-1.955a6.305 6.305 0 002.481-4.707 4.235 4.235 0 00-1.442-3.483c-1.283-1.084-3.157-1.339-5.177-.745l2.093-15.291-1.536-.21-2.46 17.976 1.283-.589c1.487-.682 3.528-1.029 4.796.043a2.732 2.732 0 01.894 2.244 4.767 4.767 0 01-1.846 3.51c-1.912 1.397-4.454 1.577-7.337 1.658l.043 1.549zM178.875 84.562h-8.35v1.55h8.35v-1.55zM152.521 84.562h-8.349v1.55h8.349v-1.55zM162.965 266.553h-47.943a22.864 22.864 0 01-21.901-16.31 22.864 22.864 0 01-.747-9.653l11.452-83.604a5.033 5.033 0 014.966-4.332h10.328a4.542 4.542 0 01-.02-.413v-3.876a5.023 5.023 0 015.013-5.013h9.302a5.014 5.014 0 013.543 1.47 5.014 5.014 0 011.47 3.543v3.876c0 .134-.006.27-.019.413h10.327a5.033 5.033 0 014.967 4.332l14.229 103.874a5.019 5.019 0 01-1.19 3.976 5.018 5.018 0 01-3.777 1.717z"
        ></path>
        <path
          fill="#2F2E41"
          d="M126.411 133.596l-27.343-10.613c-4.471-1.736-8.968-3.419-13.415-5.214a15.295 15.295 0 01-5.164-3.043 10.15 10.15 0 01-2.574-4.982c-.949-4.009-.777-8.392-.71-12.483.053-4.475.421-8.94 1.103-13.364 1.314-8.265 3.884-16.378 8.255-23.55 7.668-12.582 20.88-22.049 36.077-21.293 7.036.35 14.112 2.874 19.34 7.685a12.215 12.215 0 001.825 1.77c.465.173.97.207 1.454.097a62.76 62.76 0 011.915-.094c2.217-.094 4.438 0 6.64.278 3.96.543 7.889 1.913 10.953 4.548 2.612 2.248 4.738 5.42 4.956 8.946a10.585 10.585 0 01-3.576 8.643c-3.263 2.75-7.917 1.783-11.83 1.576l-14.335-.76-7.264-.384c-1.495-.08-1.491 2.246 0 2.325l19.305 1.023c3.103.165 6.3.567 9.405.381a10.522 10.522 0 006.459-2.598 12.888 12.888 0 003.904-12.094c-.995-4.758-4.536-8.693-8.687-11.035-5.647-3.185-12.393-3.49-18.712-3.02l.822.34c-7.148-7.672-18.098-10.911-28.378-9.778-10.854 1.197-20.507 7.253-27.443 15.533-7.733 9.232-11.947 20.743-13.586 32.58a108.411 108.411 0 00-.838 19.286c.115 2.812.35 5.708 1.435 8.334a11.933 11.933 0 005.6 6.005c2.812 1.503 5.925 2.5 8.89 3.651l10.074 3.911 19.788 7.681 5.037 1.955c1.396.542 2.001-1.706.618-2.243z"
        ></path>
        <path
          fill="#2F2E41"
          d="M83.419 54.569a12.015 12.015 0 1112.015-12.016A12.03 12.03 0 0183.419 54.57zm0-21.705a9.688 9.688 0 00-6.851 16.541 9.69 9.69 0 0016.54-6.852 9.7 9.7 0 00-9.689-9.69z"
        ></path>
        <path
          fill="#6C63FF"
          d="M221.329 198.634a4.655 4.655 0 00-3.087-5.808l-85.715-26.214a10.37 10.37 0 00-11.035 3.321 10.372 10.372 0 00-1.155 11.466 10.229 10.229 0 005.069 4.665c.344.147.697.275 1.055.385l85.714 26.213a4.658 4.658 0 003.544-.34 4.643 4.643 0 002.264-2.747l3.346-10.941z"
        ></path>
        <path
          fill="#2F2E41"
          d="M149.805 201.984a8.393 8.393 0 01-1.882-.759l-23.86-7.718c-11.36-4.736-7.579-17.279-4.474-29.261l.146-.562 24.349-4.205 18.339 8.198a8.389 8.389 0 014.419 10.62l-7.028 18.559a8.385 8.385 0 01-10.009 5.128z"
        ></path>
        <path
          fill="#3F3D56"
          d="M267.608 337H.862a.862.862 0 010-1.725h266.746a.86.86 0 01.862.863.86.86 0 01-.862.862z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0H337V337H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default NotFound;
