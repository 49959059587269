import React from "react";

const Chevron = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="6"
      fill="none"
      viewBox="0 0 10 6"
      transform={props.rotate}
    >
      <path
        fill="#532BBD"
        d="M9 0l1 .898-5 4.49-5-4.49L1 0l4 3.592L9 0z"
      ></path>
    </svg>
  );
};

export default Chevron;
