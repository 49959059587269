import React from "react";
import { v4 as uuidv4 } from "uuid";
import TipCard from "./TipCard";
import TipDetails from "./TipDetails";

const TipRow = ({
  kinds,
  tips,
  total,
  selectedCardId,
  toggleCard,
}: {
  kinds: any;
  tips: any;
  total: number;
  selectedCardId: any;
  toggleCard: any;
}) => {
  for (let i = 0; i < total - kinds.length; i++) {
    kinds.push({ placeholder: true, id: uuidv4() });
  }

  const selectedCard = kinds.find((i: any) => i.id === selectedCardId);

  return (
    <div className="tip-row">
      <section className="tip-row-cards">
        {kinds.map((i: any) => {
          return (
            <TipCard
              id={i.id}
              key={i.id}
              color={i.color}
              active={selectedCardId === i.id}
              title={i.kind}
              text={i.text}
              handleClick={() => toggleCard(i.id)}
              placeholder={i.placeholder}
            />
          );
        })}
      </section>

      {selectedCard && (
        <section className="tip-row-details">
          <TipDetails tips={tips[selectedCard.kind]} />
        </section>
      )}
    </div>
  );
};

export default TipRow;
