import TipRow from "./TipRow";
import useSelectCard from "../useSelectCard";

const TipsDesktopTablet = ({ kinds, tips }: { kinds: any; tips: any }) => {
  const { selectedCardId, toggleCard } = useSelectCard(kinds);

  return (
    <>
      <div className="tip-rows-desktop">
        <TipRow
          kinds={kinds.slice(0, 3)}
          tips={tips}
          total={3}
          selectedCardId={selectedCardId}
          toggleCard={toggleCard}
        />
        <TipRow
          kinds={kinds.slice(3, 5)}
          tips={tips}
          total={3}
          selectedCardId={selectedCardId}
          toggleCard={toggleCard}
        />
      </div>

      <div className="tip-rows-tablet">
        <TipRow
          kinds={kinds.slice(0, 2)}
          tips={tips}
          total={2}
          selectedCardId={selectedCardId}
          toggleCard={toggleCard}
        />
        <TipRow
          kinds={kinds.slice(2, 4)}
          tips={tips}
          total={2}
          selectedCardId={selectedCardId}
          toggleCard={toggleCard}
        />
        <TipRow
          kinds={kinds.slice(4, 5)}
          tips={tips}
          total={2}
          selectedCardId={selectedCardId}
          toggleCard={toggleCard}
        />
      </div>
    </>
  );
};

export default TipsDesktopTablet;
