import React from "react";
import "./Footer.css";
import Divider from "../Divider";
import { Link } from "react-router-dom";
// import lang from "./lang";

const Footer = () => {
  const clickEvent = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      <div className="footer">
        <div className="footer-container">
          <Divider />
          <div className="footer-flex">
            {/* Probably not going to use it. */}
            {/* <div className="footer-link">
              <Link
                to="/codeofconduct"
                onClick={() => {
                  clickEvent();
                }}
              >
                Code of conduct
              </Link>
            </div> */}
            <div className="footer-link">
              <Link
                to="/privacyverklaring"
                onClick={() => {
                  clickEvent();
                }}
              >
                Privacyverklaring
              </Link>
            </div>
            <div className="footer-link">
              <Link
                to="/cookies"
                onClick={() => {
                  clickEvent();
                }}
              >
                Cookies
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">
        <div className="copyright-container">©FHI All Rights Reserved.</div>
      </div>
    </>
  );
};

export default Footer;
