import React from "react";
import TipsDesktopTablet from "./DesktopTablet";
import TipsMobile from "./Mobile";
import "./Tips.css";

const Tips = ({ kinds, tips }: { kinds: any; tips: any }) => {
  return (
    <div className="tips">
      <TipsMobile kinds={kinds} tips={tips} />
      <TipsDesktopTablet kinds={kinds} tips={tips} />
    </div>
  );
};

export default Tips;
