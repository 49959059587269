import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import Button from "../Button";
import localStorageState from "../../lib/localStorageState";
import "./CookiePopup.css";
import { installGtag } from "../../lib/gtag";

const CookiePopup = () => {
  const cache = localStorageState("cookie-consent");
  const history = useHistory();
  const location = useLocation();

  const [cookieClosed, setCookieClosed] = useState(
    cache.has() && cache.get().consent !== "pending"
  );

  useEffect(() => {
    cache.getOrCreate({ consent: "pending" });
    setCookieClosed(cache.has() && cache.get().consent !== "pending");
  }, [cache]);

  const saveInstallClose = (payload: any) => {
    cache.set(payload);
    installGtag();
    setCookieClosed(true);
    window.scroll({ top: 0, behavior: "smooth" });
  };

  if (
    cookieClosed ||
    location.pathname === "/privacyverklaring" ||
    location.pathname === "/cookies" ||
    location.pathname === "/cookiebeleid"
  ) {
    return null;
  }

  return (
    <div className="cookie-popup">
      <div className="cookie-popup-container">
        <div className="cookie-popup-text">
          <h1>Wij gebruiken cookies</h1>
          <p>
            Met cookies functioneert debalanscheck.nl beter, zie je meer
            relevante informatie en vind je sneller wat je zoekt. Wil je hier
            meer over lezen? Bekijk dan onze{" "}
            <a
              href="/cookiebeleid"
              style={{ textDecoration: "underline", color: "blue" }}
            >
              cookie policy
            </a>
            .
          </p>
        </div>
        <div className="cookie-popup-buttons">
          <div>
            <Button
              onClick={() => {
                saveInstallClose({
                  consent: "agree",
                  functional: true,
                  analytics: true,
                  marketing: true,
                });
              }}
              text="Accepteer Cookies"
              small
              color="green"
            />
          </div>
          <div>
            <Button
              onClick={() => {
                saveInstallClose({
                  consent: "agree",
                  functional: true,
                  analytics: true,
                  marketing: false,
                });
              }}
              small
              text="Optionele Cookies Weigeren"
              color="white"
            />
          </div>
          <Button
            text="Cookie-instellingen"
            color="white"
            small
            onClick={() => {
              // saveInstallClose();
              history.push("/cookies");
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CookiePopup;
