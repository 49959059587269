import React from "react";
import "./Testimonials.css";
import data from "../../data/data.json";

const Testimonials = () => {
  return (
    <div className="testimonials">
      <div className="content">
        <div className="testimonials-container">
          {data.testimonials.map((testimonial: any) => {
            return (
              <div className="testimonials-card" key={testimonial.id}>
                <div className="testimonials-card-picture">
                  <img src={testimonial.picture} alt="Testimonial profile" />
                </div>
                <div className="testimonials-card-text">{testimonial.text}</div>
                <div className="testimonials-card-name">{testimonial.name}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
