import { Link } from "react-router-dom";
import Button from "../../components/Button";
import NotFound from "../../icons/NotFound";

const ResultsNotFound = () => {
  return (
    <div
      className="App-container"
      style={{
        display: "flex",
        flexWrap: "wrap",
        margin: "90px auto",
        width: "100%",
        justifyContent: "center",
      }}
    >
      <div className="results-notfound">
        <NotFound />
      </div>
      <div
        className="results-notfound-text"
        style={{ display: "inline", maxWidth: "400px" }}
      >
        <h1>Oeps je hebt nog geen resultaat</h1>
        <Link to="/test">
          <Button text="Doe de Balans Check" />
        </Link>
      </div>
    </div>
  );
};

export default ResultsNotFound;
