import React from "react";
import { Link } from "react-router-dom";
import Button from "../Button";
import "./Research.css";

const Research = (props: any) => {
  const clickEvent = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="research">
      <div className="content">
        <div className="research-image-desktop">
          <img src="images/map.png" alt="map" />
        </div>
        <div className="research-textual">
          <div className="research-title">Hoe gezond is Nederland?</div>
          <div className="research-image-mobile">
            <img src="images/map.png" alt="map" />
          </div>
          <div className="research-text">
            Veel Nederlanders hebben moeite om financieel in balans te blijven.
            Meer weten over de financiële gezondheid van Nederland? Lees over
            het onderzoek van Deloitte, ING, NIBUD en Universiteit Leiden.
          </div>
          <div className="research-button">
            <Link
              onClick={() => {
                clickEvent();
              }}
              to="/onderzoek"
            >
              <Button color={"purple"} text={"Onderzoek"} />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Research;
