import React from "react";
import "./Brands.css";
// import lang from "./lang";
import BrandsImages from "./BrandsImages";

import { Link } from "react-router-dom";
import Button from "../Button";

const Brands = () => {
  const clickEvent = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      <div className="brands">
        <div className="brands-about">
          <h1>Over de balans check</h1>
          <p>
            De Balans Check is ontwikkeld door partners uit de semipublieke
            sector, de private sector en de academische sector. Het initiatief
            wordt ondersteund door tal van bedrijven en instellingen.
          </p>
          <Link
            onClick={() => {
              clickEvent();
            }}
            to="/overdebalanscheck"
          >
            <Button color="green" text="Lees meer" />
          </Link>
        </div>
        <BrandsImages />
      </div>
    </>
  );
};

export default Brands;
