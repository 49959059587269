import TipMobileCard from "./TipMobileCard";
import useSelectCard from "../useSelectCard";

const TipsMobile = ({ kinds, tips }: { kinds: any; tips: any }) => {
  const { selectedCardId, toggleCard } = useSelectCard(kinds);

  return (
    <div className="tips-mobile">
      {kinds.map((i: any) => {
        return (
          <TipMobileCard
            id={i.id}
            key={i.id}
            color={i.color}
            active={selectedCardId === i.id}
            title={i.kind}
            text={i.text}
            handleClick={() => toggleCard(i.id)}
            tips={tips[i.kind]}
          />
        );
      })}
    </div>
  );
};

export default TipsMobile;
