import React from "react";
import Button from "../../Button";

const TipDetails = ({ tips }: { tips: any }) => {
  return (
    <div className="tip-details">
      {tips.map((i: any, index: number) => {
        return (
          <React.Fragment key={i.id}>
            <div className="tip-detail">
              <div className="tip-card-title">{i.title}</div>
              <div className="tip-card-text">{i.text}</div>
              <div className="tip-detail-button">
                <a href={i.buttonLink} target="_blank" rel="noreferrer">
                  <Button text={i.buttonText} small />
                </a>
              </div>
            </div>

            {index < tips.length - 1 && (
              <div className="tip-detail-separator"></div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default TipDetails;
