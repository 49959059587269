import React from "react";

const Tick = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      fill="none"
      viewBox="0 0 50 50"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M23.054 33c.51 0 .952-.242 1.283-.768l9.277-14.971c.207-.313.386-.697.386-1.038 0-.754-.649-1.223-1.325-1.223-.4 0-.8.242-1.104.725l-8.586 14.062-4.57-5.9c-.358-.47-.731-.64-1.173-.64-.676 0-1.242.554-1.242 1.308 0 .355.138.739.373 1.037l5.328 6.654c.428.54.842.754 1.353.754z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default Tick;
