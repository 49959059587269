import deloitte from "../../images/Deloitte.svg";
// import ing from "../../images/ING.svg";
// import rabobank from "../../images/rabobank.png";
import nibud from "../../images/nibud.png";
import leiden from "../../images/leiden.svg";

const BrandsImages = () => {
  return (
    <div className="brands-images">
      <section>
        {/* <div>
          <img src={ing} alt="ING bank" className="brands-ing" />
        </div> */}
        <div>
          <img src={deloitte} alt="Deloitte" className="brands-deloitte" />
        </div>
        {/* <div>
          <img src={rabobank} alt="Rabobank" className="brands-rabobank" />
        </div> */}
      </section>
      <section>
        <div>
          <img
            className="brands-nibud"
            src={nibud}
            alt="Nationaal Instituut voor Budgetvoorlichting"
          />
        </div>
        <div>
          <img
            src={leiden}
            alt="Universiteit Leiden"
            className="brands-leiden"
          />
        </div>
      </section>
    </div>
  );
};

export default BrandsImages;
