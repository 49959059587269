import { useState } from "react";
import data from "../data/data.json";
import localStorageState from "./localStorageState";

const cache = localStorageState("answers");

export default function useAnswers() {
  const storedAnswers: any = cache.getOrCreate({});

  const [answers, setAnswers] = useState(storedAnswers);

  return {
    answers,

    setAnswers(newAnswers: any) {
      setAnswers(newAnswers);
      cache.set(newAnswers);
    },

    isComplete() {
      return data.questions.length === Object.keys(answers).length;
    },
  };
}
