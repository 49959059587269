import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import { About, Contact, Home, Results, Test, Onderzoek } from "./pages";
import Seo from "./components/Seo";
import Cookies from "./pages/Cookies";
import Privacy from "./pages/Privacy";
import CookiePolicy from "./pages/CookiePolicy";
import NotFound from "./pages/NotFound";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import CookiePopup from "./components/CookiePopup";
import useAnswers from "./lib/useAnswers";

function App() {
  const { answers, setAnswers, isComplete } = useAnswers();

  return (
    <div className="App">
      <Router>
        <Seo />
        <CookiePopup />
        <NavBar isComplete={isComplete} />
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/test" exact>
            <Test
              answers={answers}
              setAnswers={setAnswers}
              isComplete={isComplete}
            />
          </Route>
          <Route path="/resultaten" exact>
            <Results
              answers={answers}
              setAnswers={setAnswers}
              isComplete={isComplete}
            />
          </Route>
          <Route path="/geen-resultaat" exact>
            <Results
              answers={answers}
              setAnswers={setAnswers}
              isComplete={isComplete}
            />
          </Route>
          <Route path="/overdebalanscheck" exact>
            <About />
          </Route>
          <Route path="/partners" exact>
            <Contact />
          </Route>
          <Route path="/onderzoek" exact>
            <Onderzoek />
          </Route>
          <Route path="/privacyverklaring">
            <Privacy />
          </Route>
          <Route path="/cookies">
            <Cookies />
          </Route>
          <Route path="/cookiebeleid">
            <CookiePolicy />
          </Route>
          <Route>
            <NotFound />
          </Route>
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
