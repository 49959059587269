import Button from "../../Button";

const TipMobileDetails = ({ tips }: { tips: any }) => {
  return (
    <div className="tip-details-mobile">
      {tips.map((i: any, index: number) => {
        return (
          <div key={i.id}>
            <div className="tip-card-title">{i.title}</div>
            <div className="tip-card-text">{i.text}</div>
            <a href={i.buttonLink} target="_blank" rel="noreferrer">
              <Button text={i.buttonText} small />
            </a>
          </div>
        );
      })}
    </div>
  );
};

export default TipMobileDetails;
