const appKeyName = "---fhi";

export default function localStorageState(name: string) {
  const key = `${appKeyName}---${name}`;

  const has = () => {
    return window.localStorage.getItem(key) !== null;
  };

  const get = () => {
    return JSON.parse(window.localStorage.getItem(key) as string);
  };

  const set = (payload: any) => {
    window.localStorage.setItem(key, JSON.stringify(payload));
  };

  const getOrCreate = (payload: any) => {
    if (has()) {
      return get();
    }
    set(payload);
    return payload;
  };

  const remove = () => {
    window.localStorage.removeItem(key);
  };

  return { has, get, set, getOrCreate, remove };
}
