import React from "react";
import "./ResultsCard.css";
import SliderGeldproblemen from "../../icons/SliderOngezond";
import SliderMoeite from "../../icons/SliderKwetsbaar";
import SliderGezond from "../../icons/SliderRedelijkGezond";
import SuperfitSlider from "../../icons/SliderGezond";
import Button from "../Button";

const statuses: any = {
  Geldproblemen: {
    textColor: "#fff",
    background: "#EB5757",
    Slider: SliderGeldproblemen,
  },
  "Moeite om rond te komen": {
    textColor: "#000",
    background: "#FBD13A",
    Slider: SliderMoeite,
  },
  Gezond: { textColor: "#000", background: "#99E580", Slider: SliderGezond },
  Superfit: {
    textColor: "#000",
    background: "#99E580",
    Slider: SuperfitSlider,
  },
};

const ResultsCard = ({
  totalScore,
  bucketText,
  resultText,
  resultState,
  description,
}: {
  totalScore: any;
  bucketText: string;
  resultText: string;
  resultState: string;
  description: string;
}) => {
  const { textColor, background, Slider } = statuses[bucketText];

  return (
    <div className="resultscard">
      <div className="resultscard-container">
        <div className="resultscard-text">
          <div className="resultscard-title">Jouw resultaat</div>
          {/* <Balancing /> */}

          <div className="resultscard-score">{`${
            totalScore ? totalScore : "x"
          }/100`}</div>

          <div className="resultscard-description">
            {resultText}{" "}
            <span style={{ background, color: textColor }}>{resultState}</span>
          </div>

          <Slider width="100%" />

          <div className="resultscard-tips">{description}</div>
          {/* This score is from the data totalScoreBuckets lower than 65.99 */}
          {totalScore <= 65.99 ? (
            <div className="resultscard-goto">
              <Button
                onClick={() => window.open("https://www.geldfit.nl", "_blank")}
                text="Ga naar geldfit.nl"
                color="purple"
              />
              <div className="resultscard-call">
                Of bel gratis <br />
                <a className="resultscard-phone" href="tel:0800-8115">
                  0800 - 8115
                </a>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResultsCard;
