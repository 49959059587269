import { useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "../../components/Button";
import localStorageState from "../../lib/localStorageState";
import "./Cookies.css";
import { installGtag } from "../../lib/gtag";
import { deleteAllCookies } from "../../lib/cookies";

const cache = localStorageState("cookie-consent");

const Cookies = () => {
  const history = useHistory();

  const [marketing, setMarketing] = useState(
    cache.has() && typeof cache.get().marketing !== "undefined"
      ? cache.get().marketing
      : true
  );

  const saveCookies = () => {
    window.scrollTo(0, 0);

    cache.set({
      consent: "agree",
      functional: true,
      analytics: true,
      marketing,
    });

    if (!marketing) {
      deleteAllCookies();
    }

    installGtag();
    history.push("/");
  };
  return (
    <>
      <div
        className="cookie-content content content-inst"
        style={{ textAlign: "center" }}
      >
        <h1>Cookie instellingen</h1>
        <p>
          Op deze website worden cookies gebruikt om de gebruikerservaring te
          verbeteren en het doeltreffend te laten functioneren.
        </p>
      </div>
      <div className="cookie-window">
        <div className="cookie-content content content-inst">
          <div className="cookie-text">
            <h1>Wij gebruiken de volgende cookies:</h1>
            <div className="cookie-topic">
              <div className="checkbox checkbox__disabled">
                <input
                  type="checkbox"
                  name="functioneel"
                  id="functioneel"
                  checked
                  disabled
                />
              </div>
              <p className="cookie-paragraph">
                <span className="cookie-heading">
                  <a href="/cookiebeleid#functioneel">Functioneel</a>:
                </span>{" "}
                Cookies die ervoor zorgen dat deze website naar behoren
                functioneert.
              </p>
            </div>
            <div className="cookie-topic">
              <div className="checkbox checkbox__disabled">
                <input
                  type="checkbox"
                  name="analytics"
                  id="analytics"
                  checked
                  disabled
                />
              </div>
              <p className="cookie-paragraph">
                <span className="cookie-heading">
                  <a href="/cookiebeleid#analytics">Analytics</a>:
                </span>{" "}
                Cookies waarmee wij het gebruik van de website kunnen meten.
                Gegevens die met deze cookies worden verzameld, worden
                gecombineerd en geaggregeerd ten behoeve van statistische
                analyse.
              </p>
            </div>
            <div className="cookie-topic">
              <div className="checkbox">
                <input
                  onChange={(ev) => setMarketing(!marketing)}
                  checked={marketing}
                  type="checkbox"
                  name="marketing"
                  id="marketing"
                />
              </div>
              <p className="cookie-paragraph">
                <span className="cookie-heading">
                  <a href="/cookiebeleid#marketing">Marketing en tracking</a>:
                </span>{" "}
                Als je vooraf toestemming hebt gegeven, zullen wij gebruik maken
                van tracking cookies.
              </p>
            </div>

            <Button text="Voorkeuren opslaan" small onClick={saveCookies} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Cookies;
