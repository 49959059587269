import React from "react";
import Chevron from "../../icons/Chevron";

const TipCardExpand = ({
  active,
  handleClick,
}: {
  active: boolean;
  handleClick: any;
}) => {
  return (
    <div className="tip-card-expand" onClick={() => handleClick()}>
      {active ? "Verberg tips" : "Open tips"}

      <div className="tip-card-chevron">
        <Chevron rotate={active ? "rotate(180)" : "rotate(0)"} />
      </div>
    </div>
  );
};

export default TipCardExpand;
