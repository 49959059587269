import React from "react";
import Danger from "../../icons/Danger";
import Tick from "../../icons/Tick";
import Warning from "../../icons/Warning";

const StatusIcon = ({ color }: { color: string }) => {
  return (
    <div className={`tip-card-status tip-card-status__${color}`}>
      {color === "green" && <Tick />}
      {color === "yellow" && <Warning />}
      {color === "red" && <Danger />}
    </div>
  );
};

export default StatusIcon;
