import React from "react";
import { Link } from "react-router-dom";
import Button from "../Button";
import "./Instruction.css";

const Instruction = (props: any) => {
  const clickEvent = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="instruction">
      <div className="content">
        <div className="instruction-wrapper">
          <div className="instruction-text">
            <h1>Hoe werkt de Balans Check?</h1>
            <p>
              De Balans Check bestaat uit 15 simpele vragen. De vragen gaan over
              de manier waarop jij omgaat met geldzaken. Als je de vragen hebt
              beantwoord, krijg je direct een financiële score tussen 1 en 100.
              Je score geeft aan of je financiële problemen hebt, net rondkomt,
              comfortabel bent of een heel goede financiële positie hebt.
            </p>
            <p>
              Op basis van je uitkomsten krijg je aanbevelingen die je kunnen
              helpen om je financiële balans te verbeteren.
            </p>
          </div>
          <div className="instruction-button">
            <Link
              onClick={() => {
                clickEvent();
              }}
              to="/overdebalanscheck"
            >
              <Button color={"green"} text={"Lees meer"} />
            </Link>
            <Link
              onClick={() => {
                clickEvent();
              }}
              to="/test"
            >
              <Button color={"green"} text={"Doe de balans check"} />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Instruction;
