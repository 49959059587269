import React from "react";

const HamburgerClose = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="24"
      fill="none"
      viewBox="0 0 27 24"
    >
      <path
        fill="#532BBD"
        d="M0 21H32V24H0z"
        transform="rotate(-40 0 21)"
      ></path>
      <path fill="#532BBD" d="M2 0H34V3H2z" transform="rotate(40 2 0)"></path>
    </svg>
  );
};

export default HamburgerClose;
