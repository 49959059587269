import React from "react";

import "./IntroductionCard.css";

const IntroductionCard = () => {
  return (
    <div className="introduction">
      <div className="introduction-hero">
        <div className="introduction-header">
          <h1>De Balans Check</h1>
        </div>
        <div className="introductioncard-container">
          <div className="introductioncard">
            <div className="introductioncard-text">
              <div className="introductioncard-heading">Doe de test</div>
              <div className="introductioncard-description">
                Neem 5 minuten de tijd voor 15 simpele vragen
              </div>
            </div>
          </div>
          <div className="introductioncard">
            <div className="introductioncard-text">
              <div className="introductioncard-heading">
                Check je resultaten
              </div>
              <div className="introductioncard-description">
                Ontdek hoe het ervoor staat met jouw financiële gezondheid
              </div>
            </div>
          </div>
          <div className="introductioncard">
            <div className="introductioncard-text">
              <div className="introductioncard-heading">Ga aan de slag</div>
              <div className="introductioncard-description">
                Lees concrete tips om beter in balans te komen
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntroductionCard;
