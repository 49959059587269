import React from "react";

const TipTriangle = () => {
  return (
    <div className="tip-triangle">
      <svg width="68" height="20">
        <polygon points="0,19 35,2"></polygon>
        <polygon points="35,2 68,19"></polygon>
      </svg>
    </div>
  );
};

export default TipTriangle;
