import React from "react";

const SliderKwetsbaar = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height="68"
      fill="none"
      viewBox="0 0 540 68"
    >
      <rect
        width="99"
        height="21"
        x="155"
        y="16"
        fill="#FBD13A"
        rx="10.5"
      ></rect>
      <path fill="#FBD13A" d="M0 0H132V4H0z"></path>
      <path fill="#FBD13A" d="M136 0H268V4H136z"></path>
      <path fill="#fff" d="M272 0H404V4H272z"></path>
      <path
        fill="#fff"
        d="M408 0h131c.471 0 .707 0 .854.146C540 .293 540 .53 540 1v2c0 .471 0 .707-.146.854C539.707 4 539.471 4 539 4H408V0zM45.854 30h2.9v-7h-2.6v3.1l-1.7-3.1h-2.9v7h2.6v-3.1l1.7 3.1zm6.699-7h-2.6v7h2.6v-7zm3.798 4.5h2.6v-2h-2.6V25h2.8v-2h-5.4v7h5.6v-2h-3v-.5zm3.504-4.5v2.3h1.9V30h2.6v-4.7h1.9V23h-6.4zm9.2 0v7h2.6v-2h2.1v-2h-2.1v-.8h2.6V23h-5.2zm8.595 0h-2.6v7h2.6v-7zm.799 0v2.3h1.9V30h2.6v-4.7h1.9V23h-6.4z"
      ></path>
      <path
        fill="#1E1E1C"
        d="M179.626 23h-3l-1.2 2.8V23h-2.6v7h2.6v-2.8l1.4 2.8h3l-2.1-3.5 1.9-3.5zm6.5 0h-2.3l-.75 3.85-.55-3.85h-2.7l1.5 7h2.9l.75-3.55.75 3.55h2.9l1.5-7h-2.7l-.55 3.85-.75-3.85zm7.298 4.5h2.6v-2h-2.6V25h2.8v-2h-5.4v7h5.6v-2h-3v-.5zm3.504-4.5v2.3h1.9V30h2.6v-4.7h1.9V23h-6.4zm9.346 5.1c-1 0-1.7-.65-1.7-.65l-.95 1.5s.9 1.2 3.1 1.2c1.75 0 2.85-1.1 2.85-2.25 0-2.2-2.7-2.2-2.7-2.7 0-.15.15-.3.4-.3.7 0 1.3.55 1.3.55l.95-1.5s-.9-1.1-2.7-1.1c-1.75 0-2.85 1.1-2.85 2.25 0 2.2 2.7 2.2 2.7 2.7 0 .15-.15.3-.4.3zm8.9-1.8s1.3-.25 1.3-1.45c0-1.05-.9-1.95-3.15-1.95-1.55 0-2.95.1-2.95.1v7s1.4.1 3 .1c2.5 0 3.4-.9 3.4-2 0-1.5-1.6-1.75-1.6-1.75v-.05zm-2.2-1.6h.35c.35 0 .55.2.55.45s-.2.45-.55.45h-.35v-.9zm.4 3.6h-.4v-1h.4c.4 0 .6.2.6.5s-.2.5-.6.5zm9.255-5.3h-3.4l-2.05 7h2.9l.2-1h1.3l.2 1h2.9s-2.04-6.96-2.05-7zm-2.05 4.4l.35-1.8.35 1.8h-.7zm9.745-4.4h-3.4l-2.05 7h2.9l.2-1h1.3l.2 1h2.9s-2.04-6.96-2.05-7zm-2.05 4.4l.35-1.8.35 1.8h-.7zM239.97 30l-1.7-2.7s.9-.5.9-1.95-1-2.45-3.2-2.45c-1.5 0-2.9.1-2.9.1v7h2.6v-2.3h.1l1.2 2.3h3zm-4.3-4.1v-1.1h.3c.35 0 .6.25.6.55 0 .3-.25.55-.6.55h-.3z"
      ></path>
      <path
        fill="#fff"
        d="M298.237 30l-1.7-2.7s.9-.5.9-1.95-1-2.45-3.2-2.45c-1.5 0-2.9.1-2.9.1v7h2.6v-2.3h.1l1.2 2.3h3zm-4.3-4.1v-1.1h.3c.35 0 .6.25.6.55 0 .3-.25.55-.6.55h-.3zm7.305 1.6h2.6v-2h-2.6V25h2.8v-2h-5.4v7h5.6v-2h-3v-.5zm6.504.4v-2.8h.4c.75 0 1.2.45 1.2 1.4s-.45 1.4-1.2 1.4h-.4zm4.4-1.4c0-2.15-1.34-3.6-4-3.6-1.6 0-3 .1-3 .1v7s1.4.1 3 .1c2.66 0 4-1.45 4-3.6zm3.598 1h2.6v-2h-2.6V25h2.8v-2h-5.4v7h5.6v-2h-3v-.5zm6.504-4.5h-2.6v7h5.3v-2.3h-2.7V23zm6.103 0h-2.6v7h2.6v-7zm2.799 3.9c0 .55-.35.9-.8.9-.4 0-.7-.2-.7-.2l-.7 2.1s.7.6 2 .6c1.5 0 2.8-1.3 2.8-3.4V23h-2.6v3.9zm10.601-3.9h-3l-1.2 2.8V23h-2.6v7h2.6v-2.8l1.4 2.8h3l-2.1-3.5 1.9-3.5zm6.502 4.7c-.7 0-1.2-.5-1.2-1.2s.5-1.2 1.2-1.2 1.3.55 1.3.55l1.2-1.9s-.9-1.1-2.8-1.1c-2.1 0-3.7 1.6-3.7 3.65s1.6 3.65 3.7 3.65c2 0 2.9-1.2 2.9-1.2V26.5h-2.4v1.2h-.2zm6.202-.2h2.6v-2h-2.6V25h2.8v-2h-5.4v7h5.6v-2h-3v-.5zm9.604-2.3V23h-5.6v2.2h2.3l-2.5 2.6V30h5.9v-2.2h-2.6l2.5-2.6zm5.604 1.3c0 .7-.5 1.2-1.1 1.2-.6 0-1.1-.5-1.1-1.2s.5-1.2 1.1-1.2c.6 0 1.1.5 1.1 1.2zm2.8 0c0-2.05-1.6-3.65-3.9-3.65s-3.9 1.6-3.9 3.65 1.6 3.65 3.9 3.65 3.9-1.6 3.9-3.65zm5.303 3.5h2.9v-7h-2.6v3.1l-1.7-3.1h-2.9v7h2.6v-3.1l1.7 3.1zm6.699-2.1v-2.8h.4c.75 0 1.2.45 1.2 1.4s-.45 1.4-1.2 1.4h-.4zm4.4-1.4c0-2.15-1.34-3.6-4-3.6-1.6 0-3 .1-3 .1v7s1.4.1 3 .1c2.66 0 4-1.45 4-3.6zM455.695 27.7c-.7 0-1.2-.5-1.2-1.2s.5-1.2 1.2-1.2 1.3.55 1.3.55l1.2-1.9s-.9-1.1-2.8-1.1c-2.1 0-3.7 1.6-3.7 3.65s1.6 3.65 3.7 3.65c2 0 2.9-1.2 2.9-1.2V26.5h-2.4v1.2h-.2zm6.202-.2h2.6v-2h-2.6V25h2.8v-2h-5.4v7h5.6v-2h-3v-.5zm9.604-2.3V23h-5.6v2.2h2.3l-2.5 2.6V30h5.9v-2.2h-2.6l2.5-2.6zm5.604 1.3c0 .7-.5 1.2-1.1 1.2-.6 0-1.1-.5-1.1-1.2s.5-1.2 1.1-1.2c.6 0 1.1.5 1.1 1.2zm2.8 0c0-2.05-1.6-3.65-3.9-3.65s-3.9 1.6-3.9 3.65 1.6 3.65 3.9 3.65 3.9-1.6 3.9-3.65zm5.304 3.5h2.9v-7h-2.6v3.1l-1.7-3.1h-2.9v7h2.6v-3.1l1.7 3.1zm6.698-2.1v-2.8h.4c.75 0 1.2.45 1.2 1.4s-.45 1.4-1.2 1.4h-.4zm4.4-1.4c0-2.15-1.34-3.6-4-3.6-1.6 0-3 .1-3 .1v7s1.4.1 3 .1c2.66 0 4-1.45 4-3.6z"
      ></path>
    </svg>
  );
};

export default SliderKwetsbaar;
