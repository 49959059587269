import Hero from "../../components/Hero";
import Testimonials from "../../components/Testimonials";
import Brands from "../../components/Brands";
import Explainer from "../../components/Explainer";

// import lang from "../../lang";
import Research from "../../components/Research";
import ExplainerLeft from "../../components/Instruction";

const Home = () => {
  return (
    <header className="">
      <Hero />
      <Explainer />

      <Testimonials />

      <ExplainerLeft />

      <Research />

      <div className="content">
        <Brands />
      </div>
    </header>
  );
};

export default Home;
