import React from "react";

const Hamburger = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="17"
      fill="none"
      viewBox="0 0 32 17"
    >
      <path fill="#532BBD" d="M0 0H32V3H0z"></path>
      <path fill="#532BBD" d="M0 7H32V10H0z"></path>
      <path fill="#532BBD" d="M0 14H32V17H0z"></path>
    </svg>
  );
};

export default Hamburger;
