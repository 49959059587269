import "./../../App.css";

const Home = () => {
  return (
    <div className="content content-inst">
      <h1>Hoe financieel gezond is Nederland?</h1>
      <div>
        <p>
          De coronacrisis heeft voor veel Nederlanders pijnlijk duidelijk
          gemaakt hoe kwetsbaar we zijn – lichamelijk én financieel. En hoe
          belangrijk het is om financieel in balans te zijn: door geld opzij te
          zetten, door te plannen voor de toekomst en door minder onnodige
          uitgaven te doen. Niet alleen als individu, maar ook als samenleving.
        </p>

        <p>
          Het onderzoek van Deloitte, ING, NIBUD en Universiteit Leiden: de
          financiele gezondheid van NL in 2021, vormde de basis voor de
          ontwikkeling van de Balans Check.
        </p>

        <p>
          Zelf aan de slag met je financiële gezondheid? Doe de Balans Check.
        </p>

        <p>
          Wil je als bedrijf of instelling ook een steentje bijdragen aan de
          financiële gezondheid van Nederland? Word dan partner van de Balans
          Check.
        </p>
      </div>
    </div>
  );
};

export default Home;
