import { useState } from "react";
import { useHistory } from "react-router";
import "./Results.css";
import Button from "../../components/Button";
import ResultsCard from "../../components/ResultsCard";
// import Schulden from "../../components/Schulden";
import Tips from "../../components/Tips";
import ResultsPopup from "./ResultsPopup";
import ResultsNotFound from "./ResultsNotFound";
import calculateResults from "./calculateResults";
import gtag from "../../lib/gtag";

const Results = ({
  answers,
  setAnswers,
  isComplete,
}: {
  answers: any;
  setAnswers: any;
  isComplete: any;
}) => {
  const history = useHistory();
  const [showPopup, setShowPopup] = useState(false);

  if (!isComplete()) {
    return <ResultsNotFound />;
  }

  const results = calculateResults(answers);

  gtag("event", "results_summary", {
    event_category: "results",
    event_label: `${results.resultState}`,
    value: results.totalScore,
  });

  return (
    <>
      {showPopup && (
        <ResultsPopup
          handleTest={() => {
            setShowPopup(false);
            setAnswers({});
            window.scroll({ top: 0, behavior: "smooth" });
            history.push("/test");
          }}
          handleResults={() => {
            setShowPopup(false);
            window.scroll({ top: 0, behavior: "smooth" });
          }}
        />
      )}

      <ResultsCard {...results} />

      <div className="content">
        <div className="results">
          <h1>Tips & Tricks per onderwerp</h1>
          <p>
            Je financiële gezondheid wordt bepaald door de vijf pijlers:
            Inkomen, Uitgaven, Sparen, Lenen en Plannen.
          </p>
        </div>

        <Tips kinds={results.filteredSubscore} tips={results.tipsByKind} />

        <Button
          text="Doe de test nog een keer"
          onClick={() => setShowPopup(true)}
          small
        />
      </div>

      {/* <Schulden /> */}
    </>
  );
};

export default Results;
