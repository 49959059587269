import React from "react";
import "./About.css";

const About = () => {
  return (
    <div className="content content-inst">
      <h1>Over de Balans Check</h1>
      <p>
        De Balans Check is ontwikkeld door partners uit de semipublieke sector,
        de private sector en de academische sector. Zij bundelen hun krachten om
        mensen inzicht te geven in hun financiële situatie, en handvatten te
        bieden om hun financiële gezondheid te verbeteren. Het initiatief wordt
        ondersteund door tal van bedrijven en instellingen. Dat laat zien dat
        steeds meer mensen inzien hoe belangrijk financiële gezondheid is.
      </p>
      <p>
        De Balans Check is een gevalideerd instrument, gebaseerd op
        wetenschappelijk onderzoek. Ook andere partijen kunnen de tool gebruiken
        om financiële gezondheid te meten.
      </p>
      <h1>Hoe werkt de Balans Check?</h1>

      <p>
        De Balans Check bestaat uit 15 simpele vragen. De vragen gaan over de
        manier waarop jij omgaat met geldzaken. Wat zijn je mogelijkheden, welke
        keuzes maak je en heb je controle? Je hebt geen financiële overzichten
        of cijfers nodig om de vragen te kunnen beantwoorden. Het gaat om jouw
        gevoel van controle en je inschatting van de situatie.
      </p>
      <p>
        Als je de 15 vragen hebt beantwoord, krijg je een financiële score
        tussen 1 en 100. Die score geeft aan of je financiële problemen hebt,
        net rondkomt, comfortabel bent of een heel goede financiële positie
        hebt. Je ziet ook hoe je scoort op de vijf pijlers van finananciële
        gezondheid: Inkomen, Uitgaven, Sparen, Lenen en Plannen.
      </p>

      <p>
        Op basis van je uitkomsten krijg je aanbevelingen die je kunnen helpen
        om je financiële balans te verbeteren.
      </p>

      <p>
        Je moet 18 jaar of ouder zijn om de Balans Check te doen. Door op ‘Doe
        de balans check’ te klikken bevestig je dat je ouder bent dan 18 jaar.
      </p>

      <h1>Wat je moet weten</h1>

      <p>
        Je score en alle informatie die je ontvangt om je financiële gezondheid
        te verbeteren, is algemene informatie die niet is afgestemd op jouw
        specifieke financiële situatie. De informatie mag ook niet worden
        beschouwd als advies over financiële producten. Kijk goed of de
        informatie geschikt voor je is en past bij jouw persoonlijke doelen,
        financiële situatie en wensen. Vraag om onafhankelijk, professioneel
        advies – bijvoorbeeld van een financieel adviseur – voordat je een
        beslissing neemt op basis van deze informatie.
      </p>
    </div>
  );
};

export default About;
