import { useState } from "react";
import gtag from "../../lib/gtag";

export default function useSelectCard(kinds: any) {
  const [selectedCardId, setSelectedCardId] = useState("");

  const toggleCard = (id: string) => {
    const { kind } = kinds.find((i: any) => i.id === id);

    const isOpen = id === selectedCardId;

    if (!isOpen) {
      gtag("event", "open_tip_card", {
        event_category: "results",
        event_label: kind,
      });
    }

    setSelectedCardId(isOpen ? "" : id);
  };

  return { selectedCardId, toggleCard };
}
