const NotFound = () => {
  return (
    <>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <div
          className="App-container"
          style={{
            maxWidth: "540px",
            textAlign: "left",
            margin: "0 auto",
            padding: "75px 10px",
          }}
        >
          <h1>404 - We kunnen de pagina die je zoekt niet vinden</h1>
        </div>
      </div>
    </>
  );
};

export default NotFound;
