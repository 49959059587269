import { useRef } from "react";
import StatusIcon from "../StatusIcon";
import TipCardExpand from "../TipCardExpand";
import TipMobileDetails from "./TipMobileDetails";

const TipMobileCard = ({
  id,
  title,
  text,
  color,
  active,
  handleClick,
  tips,
}: {
  id: string;
  title: string;
  text: string;
  color: string;
  active: boolean;
  handleClick: any;
  tips: any;
}) => {
  const cardRef: any = useRef();

  return (
    <div
      ref={(ref) => (cardRef.current = ref)}
      className={`tip-card tip-card-mobile ${active ? "tip-card__active" : ""}`}
    >
      <StatusIcon color={color} />

      <div className="tip-card-title">{title}</div>

      <div className="tip-card-text">{text}</div>

      {active && <TipMobileDetails tips={tips} />}

      <TipCardExpand
        active={active}
        handleClick={() => {
          handleClick(id);
          window.requestAnimationFrame(() =>
            // Wait for next tick to get the correct offsetTop
            // (after expanding/collapsing finished)
            window.scroll({
              top: cardRef.current.offsetTop - 30,
            })
          );
        }}
      />
    </div>
  );
};

export default TipMobileCard;
