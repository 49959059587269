import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import "./CookiePolicy.css";

const CookiePolicy = () => {
  const location = useLocation();

  const sectionRefs: any = useRef({});

  // JS was needed because regular HTML anchor stuff was not working.
  // Maybe React Router is creating the issue?
  useEffect(() => {
    const { hash } = location;

    if (!hash) {
      return;
    }

    window.scroll({
      top: sectionRefs.current[hash.replace("#", "")].offsetTop - 40,
      behavior: "smooth",
    });
  }, [location]);

  return (
    <div className="content content-inst content-cookie-policy">
      <h1 style={{ fontSize: 32 }}>Cookiebeleid</h1>

      <h1
        id="functioneel"
        style={{ fontSize: 26 }}
        ref={(ref) => (sectionRefs.current.functioneel = ref)}
      >
        Functioneel
      </h1>

      <p>Functionele cookies zijn nodig om de website goed te laten werken.</p>

      <div className="cookie-table-container">
        <table className="cookie-table">
          <thead>
            <tr>
              <th>Beheerder</th>
              <th>Categorie</th>
              <th>Cookie naam</th>
              <th>Doel</th>
              <th>Bewaartijd</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>localStorage</td>
              <td>Functioneel</td>
              <td>---fhi---answers</td>
              <td>
                Het onthouden van de vragen die zijn beantwoord zodat de
                scoreberekening kan plaatsvinden. De data wordt lokaal
                opgeslagen en wordt nooit gedeeld met externe servers.
              </td>
              <td>Verloopt nooit</td>
            </tr>
          </tbody>
        </table>
      </div>

      <h1
        id="marketing"
        style={{ fontSize: 26, marginTop: "4rem" }}
        ref={(ref) => (sectionRefs.current.analytics = ref)}
      >
        Analytics
      </h1>

      <h2>Google Analytics</h2>
      <p>
        Deze cookies zijn van Google. Hiermee kunnen we bezoekersstatistieken
        analyseren en internetgedrag van verschillende apparaten koppelen. Met
        deze analyses verbeteren we onze website en meten we de effectiviteit
        van onze campagnes. Je webadres wordt automatisch onherkenbaar gemaakt.
        Er worden dus geen herleidbare persoonsgegevens opgeslagen.
      </p>

      <div className="cookie-table-container">
        <table className="cookie-table">
          <thead>
            <tr>
              <th>Beheerder</th>
              <th>Categorie</th>
              <th>Cookie naam</th>
              <th>Doel</th>
              <th>Bewaartijd</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Google</td>
              <td>Toestemming voor analytics cookies</td>
              <td>_ga, _gid</td>
              <td>
                Analyseren van bezoekersstatistieken en internetgedrag van
                verschillende apparaten koppelen.
              </td>
              <td>Maximaal 1 jaar na plaatsen cookie</td>
            </tr>
          </tbody>
        </table>
      </div>

      <h1
        id="marketing"
        style={{ fontSize: 26, marginTop: "4rem" }}
        ref={(ref) => (sectionRefs.current.marketing = ref)}
      >
        Marketing en tracking
      </h1>

      <h2>Facebook cookies</h2>
      <p>
        Deze cookie wordt wellicht door Facebook gebruikt om een profiel van uw
        interesses samen te stellen en u relevante advertenties op andere
        websites te tonen. Ze slaan geen directe persoonlijke informatie op,
        maar ze zijn gebaseerd op unieke identificatoren van uw browser en
        internetapparaat. De data sturen wij via een uniek cookie ID of
        versleuteld e-mailadres naar Facebook. Door dit te delen zijn we in
        staat om te meten of onze advertenties op Facebook succesvol zijn of
        juist niet. We koppelen jouw unieke cookie ID aan een advertentie en zo
        herleiden we of de advertentie waarop je geklikt hebt, relevant voor je
        was. Als u deze cookies niet toestaat, zult u minder op u gerichte
        advertenties zien. Deze cookies stellen ons in staat bezoekers en hun
        herkomst te tellen zodat we de prestatie van onze campagnes kunnen
        analyseren en verbeteren. Alle informatie die deze cookies verzamelen
        wordt geaggregeerd. Facebook plaatst verschillende cookies. Ze worden
        allemaal gebruikt om het advertentie-aanbod op Facebook te verbeteren.
      </p>

      <div className="cookie-table-container">
        <table className="cookie-table">
          <thead>
            <tr>
              <th>Beheerder</th>
              <th>Categorie</th>
              <th>Cookie naam</th>
              <th>Doel</th>
              <th>Bewaartijd</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Facebook</td>
              <td>Toestemming voor marketing en tracking cookies</td>
              <td>
                wd, usida, presence, spin, cppo, c_user, dpr, sb, _fbp, xs,
                datr, fr
              </td>
              <td>
                Het meten van de effectiviteit van onze advertenties. Tonen van
                gepersonaliseerde aanbiedingen op Facebook en Instagram en
                zoeken naar geschikte doelgroepen op Facebook en Instagram om
                onze advertenties aan te laten zien.
              </td>
              <td>Maximaal 2 jaar na plaatsen cookie</td>
            </tr>
          </tbody>
        </table>
      </div>

      <h2 style={{ marginTop: "2rem" }}>Google Ads cookies</h2>

      <p>
        Deze cookie wordt wellicht door Google gebruikt om een profiel van uw
        interesses samen te stellen en u relevante advertenties op andere
        websites te tonen. Ze slaan geen directe persoonlijke informatie op,
        maar ze zijn gebaseerd op unieke identificatoren van uw browser en
        internetapparaat. De data sturen wij via een uniek cookie ID of
        versleuteld e-mailadres naar Facebook. Door dit te delen zijn we in
        staat om te meten of onze advertenties op Facebook succesvol zijn of
        juist niet. We koppelen jouw unieke cookie ID aan een advertentie en zo
        herleiden we of de advertentie waarop je geklikt hebt, relevant voor je
        was. Als u deze cookies niet toestaat, zult u minder op u gerichte
        advertenties zien. Deze cookies stellen ons in staat bezoekers en hun
        herkomst te tellen zodat we de prestatie van onze campagnes kunnen
        analyseren en verbeteren. Alle informatie die deze cookies verzamelen
        wordt geaggregeerd. Google plaatst verschillende cookies. Ze worden
        allemaal gebruikt om het advertentie-aanbod op Google te verbeteren.
      </p>

      <div className="cookie-table-container">
        <table className="cookie-table">
          <thead>
            <tr>
              <th>Beheerder</th>
              <th>Categorie</th>
              <th>Cookie naam</th>
              <th>Doel</th>
              <th>Bewaartijd</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Google</td>
              <td>Toestemming voor marketing en tracking cookies</td>
              <td>
                SAPISID, APISID, HSID, __Secure-3PSID, SID, SIDCC,
                __Secure-3PSID, __Secure-1PSID, APISID, __Secure-1PAPISID,
                SAPISID, SSID, __Secure-1PSID, __Secure-3PAPISID, SSID,
                __Secure-1PAPISID, __Secure-3PSIDCC, __Secure-3PAPISID, SID,
                HSID, NID, CONSENT, NID, OTZ, OGPC, SEARCH_SAMESITE, CONSENT,
                1P_JAR, S
              </td>
              <td>
                Het meten van de effectiviteit van onze advertenties. Tonen van
                gepersonaliseerde aanbiedingen op Google.
              </td>
              <td>Maximaal 2 jaar na plaatsen cookie</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CookiePolicy;
