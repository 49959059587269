import React from "react";
import "./Hero.css";
import Button from "../Button";
import { Link, withRouter } from "react-router-dom";
import Logo from "../../icons/Logo";

const Hero = () => {
  return (
    <div className="hero">
      <div className="hero-image-container">
        <div className="hero-image">
          {/* <img src={HeroImage} alt="Hero: family with financial issues" /> */}
          <div className="hero-quote">
            <h1>
              "Natuurlijk kiest ze precies de schoenen die niet in de sale
              zijn..."
            </h1>
          </div>
          <div className="hero-overlay">
            <div className="hero-titleimage">
              <Logo />
            </div>
            <div className="hero-wrapper">
              <div className="hero-description">
                Wat kan je doen om financieel gezonder te worden?
              </div>
              <div className="hero-explain">
                <div className="hero-explain-textwrapper">
                  <div className="hero-explain-heading">Doe de test</div>
                  <div className="hero-explain-description">
                    Neem 5 minuten de tijd voor 15 simpele vragen
                  </div>
                </div>
              </div>
              <div className="hero-explain">
                <div className="hero-explain-textwrapper">
                  <div className="hero-explain-heading">
                    Check je resultaten
                  </div>
                  <div className="hero-explain-description">
                    Ontdek hoe het ervoor staat met jouw financiële gezondheid
                  </div>
                </div>
              </div>
              <div className="hero-explain">
                <div className="hero-explain-textwrapper">
                  <div className="hero-explain-heading">Ga aan de slag</div>
                  <div className="hero-explain-description">
                    Bekijk concrete tips om je geldzaken op orde te brengen en
                    te houden voor nu en later
                  </div>
                </div>
              </div>
              <div className="hero-button">
                <Link to="/test" onClick={() => window.scroll({ top: 0 })}>
                  <Button color={"green"} text={"Doe de balans check"} />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Hero);
