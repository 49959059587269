import "./ProgressBar.css";
import data from "../../data/data.json";

const ProgressBar = ({ answers }: { answers: any }) => {
  const percentage =
    (Object.keys(answers).length / data.questions.length) * 100;

  return (
    <div className="progressbar-wrapper">
      <div className="progressbar">
        <div
          className="progressbar-value"
          style={{ width: `${percentage}%` }}
        ></div>
      </div>
    </div>
  );
};

export default ProgressBar;
