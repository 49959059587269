import React from "react";
import "./Button.css";
// import lang from "./lang";

const Button = (props: any) => {
  return (
    <div
      onClick={props.onClick || function () {}}
      className={`button
      ${props.small ? "button__small" : ""}
      ${props.color === "green" ? "button-green" : ""}
      ${props.color === "purple" ? "button-purple" : ""}
      ${props.color === "white" ? "button-white" : ""}
      `}
    >
      {props.text}
    </div>
  );
};

export default Button;
