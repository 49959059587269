import { useEffect } from "react";
import { useLocation } from "react-router";

import data from "./data.json";

export default function Seo() {
  const location = useLocation();
  const _data = data as any;

  let title: string, description: string;

  if (_data[location.pathname]) {
    title = _data[location.pathname].title;
    description = _data[location.pathname].description;
  } else {
    title = _data["/"].title;
    description = _data["/"].description;
  }

  useEffect(() => {
    document.title = `${title} | De Balans Check`;
    document
      .querySelector("meta[name=description]")
      ?.setAttribute("content", description);
  }, [description, title]);

  return null;
}
