import React from "react";

const Warning = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      fill="none"
      viewBox="0 0 50 50"
    >
      <path
        fill="#fff"
        d="M23.09 29.96c-.24 0-.45-.21-.45-.42l-.45-14.79c0-.24.21-.45.45-.45h5.28c.24 0 .45.21.45.45l-.48 14.79c0 .21-.21.42-.45.42h-4.35zm2.19.48c2.58 0 3.3 1.38 3.3 2.46 0 1.14-.72 2.52-3.3 2.52-2.55 0-3.3-1.38-3.3-2.52 0-1.08.75-2.46 3.3-2.46z"
      ></path>
    </svg>
  );
};

export default Warning;
