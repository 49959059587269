import localStorageState from "./localStorageState";
const cache = localStorageState("cookie-consent");

const isTrackingAllowed = () => cache.has() && cache.get().analytics;

// Based on: https://github.com/idmadj/ga-gtag
export const installGtag = () => {
  if (!isTrackingAllowed()) {
    return;
  }

  const trackingId = process.env.REACT_APP_GTAG;
  const scriptId = "ga-gtag";
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
  window.dataLayer.push({ marketing: cache.get().marketing ? "on" : "off" });

  if (document.getElementById(scriptId)) return;

  const { head } = document;
  const script = document.createElement("script");
  script.id = scriptId;
  script.type = "text/javascript";
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtm.js?id=${trackingId}`;
  head.insertBefore(script, head.firstChild);
};

export const gtag = function () {
  // console.log("gtag", arguments);
  // Can't use arrow func + destructuring as Google expects
  // arguments objects in dataLayer (not an array of arguments).
  if (!isTrackingAllowed()) {
    return;
  }

  window.dataLayer.push(arguments);
};

export default gtag;
