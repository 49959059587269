import data from "../../data/data.json";

const weightFormula = (unscaledScore: any, minWeight: any, maxWeight: any) => {
  return 100 - ((unscaledScore - minWeight) / (maxWeight - minWeight)) * 100;
};

export default function calculateResults(storedAnswers: any) {
  const {
    subscore,
    recommendations,
    totalScoreBuckets,
    questions,
    formuladata,
  } = data;

  let bucketText = "";
  let resultText = "Je moet de test nog afmaken.";
  let resultState = "";
  let description =
    "Om er achter te komen of je in balans bent, moet je de test nog afmaken.";

  let filteredSubscore: any = [];
  let filteredRecommendations: any = [];
  let totalScore = 0;
  let unscaledTotalScore = 0;

  let unscaled: any = {
    Inkomen: 0,
    Uitgaven: 0,
    Sparen: 0,
    Lenen: 0,
    Plannen: 0,
    Overige: 0,
  };

  let score: any = {
    Inkomen: 0,
    Uitgaven: 0,
    Sparen: 0,
    Lenen: 0,
    Plannen: 0,
    Overige: 0,
  };

  questions.forEach(({ answers, kind }) => {
    const filteredAnswer = answers.filter((item) =>
      Object.values(storedAnswers).includes(item.id)
    );

    filteredAnswer.forEach(({ weight }) => {
      unscaled[kind] += weight;
      unscaledTotalScore += weight;
    });
  });

  formuladata.forEach(({ kind, minWeight, maxWeight }) => {
    if (kind === "Total") {
      totalScore = Math.round(
        weightFormula(unscaledTotalScore, minWeight, maxWeight)
      );
    } else {
      score[kind] = Math.round(
        weightFormula(unscaled[kind], minWeight, maxWeight)
      );
    }
  });

  subscore.forEach((subscore: any) => {
    if (
      score[subscore.kind] >= subscore.lowerbound &&
      score[subscore.kind] <= subscore.upperbound
    ) {
      filteredSubscore.push(subscore);
    }
  });

  recommendations.forEach((recommendation: any) => {
    recommendation.answerId.forEach((answer: string) => {
      if (Object.values(storedAnswers).includes(answer)) {
        filteredRecommendations.push(recommendation);
      }
    });
  });

  totalScoreBuckets.forEach((bucket: any) => {
    if (totalScore >= bucket.lowerbound && totalScore <= bucket.upperbound) {
      if (bucketText !== bucket.bucket) {
        bucketText = bucket.bucket;
        resultText = bucket.resultText;
        resultState = bucket.resultState;
        description = bucket.description;
      }
    }
  });

  const tipsByKind = filteredRecommendations.reduce((acc: any, next: any) => {
    acc[next.kind] = acc[next.kind] || [];
    acc[next.kind].push(next);
    return acc;
  }, {});

  return {
    bucketText,
    resultText,
    resultState,
    description,
    totalScore,
    filteredRecommendations,
    filteredSubscore,
    tipsByKind,
  };
}
