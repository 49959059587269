import React, { useState } from "react";
import { Link, NavLink, withRouter } from "react-router-dom";
import Logo from "../../icons/Logo";

import "./NavBar.css";
import Hamburger from "../../icons/Hamburger";
import HamburgerClose from "../../icons/HamburgerClose";

const NavBar = (props: any) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`navbar ${isOpen ? "navbar__open" : ""}`}>
      <div className="navbar-content">
        <div className="navbar-left">
          <div className="navbar-hamburger" onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? <HamburgerClose /> : <Hamburger />}
          </div>
          <Link
            to="/"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <div className="navbar-logo">
              <Logo />
            </div>
            {/* <div className="navbar-brandname">De Balans Check</div> */}
          </Link>
        </div>
        <div className="navbar-right">
          <NavLink
            activeStyle={{ fontWeight: 900 }}
            to="/test"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <div className="navbar-link" data-text="Test">
              Test
            </div>
          </NavLink>
          <NavLink
            activeStyle={{ fontWeight: 900 }}
            to={props.isComplete() ? "/resultaten" : "/geen-resultaat"}
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <div className="navbar-link" data-text="Resultaten">
              Resultaten
            </div>
          </NavLink>
          <NavLink
            activeStyle={{ fontWeight: 900 }}
            to="/overdebalanscheck"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <div className="navbar-link" data-text="Over de Balans Check">
              Over de Balans Check
            </div>
          </NavLink>
          <NavLink
            activeStyle={{ fontWeight: 900 }}
            to="/partners"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <div className="navbar-link" data-text="Partners">
              Partners
            </div>
          </NavLink>
          <NavLink
            activeStyle={{ fontWeight: 900 }}
            to="/onderzoek"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <div className="navbar-link" data-text="Onderzoek">
              Onderzoek
            </div>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default withRouter(NavBar);
