import "./../../App.css";
import "./Contact.css";
import "./../../components/Brands/Brands.css";
import BrandsImages from "../../components/Brands/BrandsImages";

const Contact = () => {
  return (
    <div className="content">
      <div className="contact">
        <div className="contact-description">
          <h1>Ook partner worden?</h1>
          <p>
            Ziet jouw organisatie ook het belang van financiële gezondheid? Wil
            je bijdragen aan een sterker financieel bewustzijn binnen de
            maatschappij? Doe mee en word partner van de Balans Check! Alle
            bedrijven en instellingen – groot of klein, publiek of privaat –
            zijn welkom.
          </p>

          <p>
            Als partner kun je gebruikmaken van de gegevens van het onderzoek de
            "Financiële gezondheid van NL in 2021" van Deloitte, ING, NIBUD en
            Universiteit Leiden. Daarmee kun je de boodschap over financiële
            gezondheid vanuit je eigen organisatie en binnen je eigen sector
            uitdragen. Ook kun je de Balans Check een plek geven in je eigen
            online omgeving om de financiële gezondheid van je medewerkers,
            klanten of bijvoorbeeld inwoners te meten.
          </p>

          <p>
            Uiteraard gaan we graag met je in gesprek om te bekijken wat de
            Balans Check voor jouw organisatie kan toevoegen. Neem hiervoor
            contact op met{" "}
            <a href="mailto:info@debalanscheck.nl?subject=De Balans Check">
              info@debalanscheck.nl
            </a>
            .
          </p>
        </div>
        <div>
          <BrandsImages />
        </div>
      </div>
    </div>
  );
};

export default Contact;
